import React from 'react'
import Footer from '../components/Footer'
import Headline from '../components/Headline'
import IntroGradient from '../components/IntroGradient'
import LogoFixed from '../components/LogoFixed'
import Menu from '../components/Menu'
import Seo from '../components/Seo'
import { s, colors, alpha } from '../style'

const MarketingDirector = () => {

  return (
    <>
      <Seo 
        title='Dyrektor Marketingu - Doradztwo i analizy marketingowe'
        desc='Dzięki tej usłudze możesz zweryfikować dotychczasowe działania i uzyskać dostęp do wiedzy niedostępnej dla większości firm z sektora MŚP. Rozwijaj swój marketing z CMO na godziny.' 
        url='/dyrektor-marketingu-na-godziny/' 
      />
      <Menu />
      <LogoFixed />
      <IntroGradient />
      <Headline back='CMO'>
        <h1>
          DYREKTOR MAREKTINGU NA GODZINY
        </h1>
        <p>Dyrektor marketingowy na godziny może być świetnym rozwiązaniem dla firm, które potrzebują kompetentnej osoby do prowadzenia kampanii marketingowych, ale nie mogą sobie pozwolić na cały etat. Możesz wynająć dyrektora marketingu na godziny lub na stałe, w zależności od potrzeb i możliwości Twojej firmy.</p>
      </Headline>

      <div css={sInner}>
        <div css={sText}>

          <h2>Za co odpowiada Dyrektor Marketingu?</h2>
          <p>Dyrektor marketingowy jest odpowiedzialny za opracowywanie i realizację strategii marketingowej dla firmy. Jest to kluczowa rola w organizacji, ponieważ bez dobrze przemyślanej i skutecznie wdrażanej strategii marketingowej firma może mieć problemy ze sprzedażą i rozwojem.</p>
          <p>Dyrektor marketingu musi mieć doświadczenie w tworzeniu i prowadzeniu kampanii marketingowych, a także doświadczenie w zarządzaniu zespołami marketingowymi. Powinien również posiadać dobrą znajomość rynku, konkurencji i oczekiwań klientów.</p>
          <p>Osoba na tym stanowisku jest odpowiedzialna za koordynowanie działań zespołu marketingowego i współpracę z innymi działami w firmie, takimi jak sprzedaż, produkcja czy finanse. Musi także ściśle współpracować z zarządem firmy, aby uzyskać jak najlepsze wyniki dla organizacji. </p>

          <h2>Jakie są przewagi wynajęcia dyrektora marketingowego na godziny?</h2>
          <p>Dyrektor marketingowy na godziny może być także świetnym wyborem dla firm, które mają doświadczony zespół marketingowy, ale potrzebują kogoś, kto będzie ich koordynował i kontrolował. Możesz wynająć dyrektora marketingu tylko na okres, kiedy będziesz potrzebował jego pomocy, dzięki czemu zaoszczędzisz pieniądze.</p>

          <h2>Co zyskam dzięki zatrudnieniu zewnętrznego Dyrektora Marketingu?</h2>
          <p>Zatrudnienie zewnętrznego dyrektora marketingu może być świetnym rozwiązaniem dla firm, które chcą zwiększyć swoją obecność na rynku. Zatrudnienie kogoś z zewnątrz może pomóc Ci lepiej zrozumieć rynek, konkurencję i oczekiwania klientów.</p>

          <h2>Ile kosztuje wynajęcie dyrektora marketingu?</h2>
          <p>Cena wynajęcia dyrektora marketingu może się różnić w zależności od jego doświadczenia, umiejętności i tego, ile czasu potrzebujesz jego pomocy. Wynajem dyrektora marketingu na godziny może kosztować od 150 do 400 złotych za godzinę, w zależności od tych czynników. Wynajem dyrektora marketingu na stałe może kosztować od 10 000 do 30 000 złotych miesięcznie.</p>

        </div>
      </div>

      <Footer />
    </>
  )
}

const sInner = {
  padding: '0 4rem',
  maxWidth: '900px',
  margin: '0 auto',
  [s.xs]: { padding: '0 2rem' },
}

const sText = {
  h2: {
    fontSize: '1.5rem',
    marginBottom: '2rem',
    marginTop: '2rem',
    lineHeight: '1.3',
    color: colors.white,
    fontFamily: 'Red Hat Display',
    [s.xs]: { fontSize: '1.25rem' },
  },

  p: {
    fontSize: '1.25rem',
    color: colors.white,
    marginBottom: '1.5rem',
    opacity: '0.44',
    lineHeight: '1.5',
    [s.xs]: { fontSize: '1rem', },
  },
}

export default MarketingDirector
