import { Link } from 'gatsby'
import React from 'react'
import Logo from '../assets/Logo'
import { s } from '../style'

const LogoFixed = () => {
  return (
    <Link to="/" css={sLogo}>
      <Logo />
    </Link>
  )
}

const sLogo = {
  animation: 'fade-in 1s cubic-bezier(0.25, 1, 0.5, 1)',
  position: 'absolute',
  top: 64,
  left: 65,
  zIndex: 101,
  svg: {
    height: 50,
    width: 'max-content',
  },
  [s.sm_down]: {
    zIndex: 101,
    position: 'absolute',
    minWidth: 96,
    svg: {
      height: 40,
    },
    top: 32,
    left: 32,
  },
  [s.lg]: {
    zIndex: 11,
    position: 'fixed',
  },
}

export default LogoFixed
