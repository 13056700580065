import React from 'react'
import { colors, s, alpha } from '../style'

const IntroGradient = () => {
  return <div css={sGradient} />
}

const sGradient = {
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  width: '100vw',
  height: '100vw',
  // prettier-ignore
  background: `-webkit-radial-gradient(
    top center,
    circle cover,
    ${colors.red.concat(alpha[100])} 0%,
    ${colors.red.concat(alpha[0])} 33%,
    ${colors.red.concat(alpha[0])} 100%
    )`,

  [s.sm_down]: {
    height: '100vh',
    width: '100vw',
    // prettier-ignore
    background: `-webkit-radial-gradient(
      top center,
      circle cover,
      ${colors.red.concat(alpha[100])} 0%,
      ${colors.red.concat(alpha[0])} 60%,
      ${colors.red.concat(alpha[0])} 100%
      )`,
  },
  [s.lg]: {
    opacity: 0.77,
  },
}

export default IntroGradient
